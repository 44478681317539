@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');

.App {
  text-align: center;
  background-color: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

/* GLOBAL */
.company-logo {
  height: 64px;
  width: calc(40px + 1vmin);
}

.background-image-container {
  vertical-align: top;
  position: relative;
  text-align: center;
  background-color: #000000;
  mask-size: auto auto;
}

.background-image {
  vertical-align: top;
  width: 100%;
  height: auto;
  opacity: 0.5;
}

.background-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: clamp(2rem, 6vw, 6rem); /* set min font size to 2rem, max font size to 6rem, and font size to 6vw (viewport width) */
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 1;
  width: 80%; /* set maximum width to 90% of container width to prevent overflowing */
  max-height: 70%;
}

.body-content-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #ffffff;
  padding: 25px 50px 25px 50px
}

.body-container {
  height: 300px;
  width: 400px;
  margin: 30px;
  background-color: #000000;
  border-radius: 20px;
}

.body-block-text {
  font-size: calc(3px + 2vmin);
  font-weight: 400;
  width: 60%;
  color: #000000;
}
/* Top 10 */

.top-10-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 400px;
  width: 80%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.top-10-table > thead tr {
  background-color: rgb(54, 207, 255);
  color: #ffffff;
  text-align: left;
}

.top-10-table th,
.top-10-table td {
    padding: 8px 10px;
}

.top-10-table tbody tr{
  border-bottom: 1px solid #dddddd;
}

.top-10-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.top-10-table tbody tr:last-of-type {
  border-bottom: 2px solid rgb(54, 207, 255);
}
/* About us page */

.about-us-title {
  font-family: "Lato", Helvetica, sans-serif;
  line-height: 2;
  margin-left: 30px;
  margin-right: 30px;
  color: #767676;
  font-weight: bolder;
  font-size: xx-large;
  flex-grow: 1;
}

.about-us-title-big {
  font-family: "Adamina", "Lato", Helvetica, sans-serif;
  line-height: 2;
  margin-left: 30px;
  margin-right: 30px;
  color: #767676;
  font-weight: bolder;
  font-size: 250%;
  flex-grow: 1;
}

.about-us-section-container {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 50px 50px 50px 50px;
  padding-bottom: 50px;
  flex-direction: row;
  order: 9;
  border-bottom: 1px solid #e1e1e1;
}



.about-us-image2-container {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
  max-width: 55%;
  
  border-radius: 20px;
}

.about-us-title-over-text {
  display: flex;
  justify-content: left;
  flex-direction: column;
  width: auto;
}

.about-us-text {
  color: #767676;
  font-size: x-large;
  font-family: "Lato", Helvetica, sans-serif;
  line-height: 2;
  margin-left: 30px;
  margin-right: 30px;
  flex-grow: 2;
}

.meet-the-team-section {
  flex: auto;
  flex-direction: row;
  flex-wrap: wrap;
}

.meet-the-team-tile-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-members-all-images{
  display: flex;
  flex-wrap: wrap;
  flex: row;
  width: 100%;
  justify-content: center;
  justify-content: space-between;
  align-items: flex-start;
}

.team-members-container {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  width:50%;
}

.team-member {
  position: relative;
  width: 100%;
  padding: 10px;
}

.team-member-wrapper {
  position: relative;
  display: inline-block;
}

.team-member-link {
  width: 50%;
}

.team-member {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.team-member-img-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 5px;
  overflow: hidden;
}


.team-member-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}


.team-member-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-family: sans-serif;
}

.team-member-name {
  font-weight: bold;
  font-size: 2.5vw;
  min-width: 100px;
  margin-bottom: 5px;
}

.team-member-title {
  font-size: 1.5vw;
  min-width: 100px;
  margin-bottom: 5px;
}


@media (max-width: 1024px) {
  .about-us-section-container {
    flex-direction: column;
    
  }
  .about-us-image2-container {
    max-width: 600px; 
    min-width: 350px; 
    width: 100%;
    order: 9;
  }

  .about-us-title-over-text {
    order: 10;
  }
  .about-us-title-big {
    order: 10;
  }
  .team-members-all-images{
    flex-direction: column;
    flex-wrap: wrap;
  }
  .team-members-container{
    width:100%;
  }
}

/* DATA FINDINGS */
/* DATA FINDINGS */

.data-findings-title {
  font-family: "Lato", Helvetica, sans-serif;
  line-height: 2;
  margin-left: 30px;
  margin-right: 30px;
  color: #767676;
  font-weight: bolder;
  font-size: xx-large;
  flex-grow: 1;
}

.data-findings-section-container{
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 50px 50px 50px 50px;
  padding-bottom: 50px;
  flex-direction: row;
  order: 9;
  border-bottom: 1px solid #e1e1e1;
}
.data-findings-section-container-bottom{
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 50px 50px 50px 50px;
  padding-bottom: 50px;
  flex-direction: row;
  order: 9;
}

.data-findings-image-container{
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
  max-width: 55%;
  border-radius: 20px;
}

.data-findings-title-over-text {
  display: flex;
  justify-content: left;
  flex-direction: column;
  width: auto;
}

.data-findings-text {
  color: #767676;
  font-size: x-large;
  font-family: "Lato", Helvetica, sans-serif;
  line-height: 2;
  margin-left: 30px;
  margin-right: 30px;
  flex-grow: 2;
}

@media (max-width: 1024px) {
  .data-findings-section-container {
    flex-direction: column;
    
  }
  .data-findings-section-container-bottom {
    flex-direction: column;
    
  }
  .data-findings-image-container {
    max-width: 600px; 
    min-width: 350px; 
    width: 80%;
    order: 9;
  }

  .data-findings-title-over-text {
    order: 10;
  }
  .data-findings-title-big {
    order: 10;
  }
}

/* NAVBAR */
.navbar-content-area {
  background-color: #ffffff;
  height: 64px;
  width: 98%;
  padding: 0px 1% 0px 1%;
}

.navbar-containers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 64px;
}

.navbar-page-titles-container {
  display: flex;
  flex-direction: row;
}

.navbar-page-titles {
  display: flex;
  font-display: flex;
  margin: 0px calc(5px + 1vmin) 0px calc(5px + 1vmin);
  font-size: calc(6px + 1vmin);
  font-weight: 300;
  text-decoration: none;
  color: #000;
}

.navbar-company-logo-text {
  align-self: center;
  margin: 0px calc(5px + 1vmin) 0px calc(5px + 1vmin);
  font-size: calc(14px + 1vmin);
  font-weight: bolder;
  font-style: italic;
  text-decoration: none;
  color: #000;
}

/* FOOTER */

.footer-content-area {
  background-color: #ffffff;
  position: flex-end;
  /* position: absolute; */
  bottom: 0;
  height: 64px;
  width: 98%;
  padding: 0px 1% 0px 1%;
  border-top: 1px solid #282c34;
}

.footer-page-titles-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer-page-titles {
  display: flex;
  font-display: flex;
  margin: 0px calc(5px + 1vmin) 0px calc(5px + 1vmin);
  font-size: calc(6px + 1vmin);
  font-weight: 300;
  text-decoration: none;
  color: #000;
}

/* LIVEMAP */

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}


.deckgl-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: calc(100% - 64px); /* subtract navbar height */
  border: 1px solid black;
  
}

.map-search {
  position: absolute;
  margin: 40px;
  z-index: 10;
  display: flex;
  align-items: center;
}

.map-search-button {
  margin-left: 10px;
  background-color: #ffffff;
  border: 8px solid white;
  border-radius: 12px;
  font-size: medium;
}

.map-search-button:active {
  transform: scale(0.95); /* This shrinks the button slightly when it's clicked */
}

.suggestions {
  position: absolute;
  margin-top: 5px;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.suggestions div {
  padding: 10px;
  cursor: pointer;
}

.suggestions div:hover {
  background-color: #f5f5f5;
}

.map-search-text {
  font-size: xx-large;
  border-radius: 12px;
  border: none;
  padding-left: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}



.map-zoom-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-top: calc(75vh);
  margin-left: calc(100% - 80px);
  z-index: 10;
  justify-content: flex-end;
}

.map-zoom {
  margin-bottom: 20px;
}

.map-zoom-text {
  font-size: xx-large;
  width: 50px;
  border-radius: 30px;
  border: none;
}

.weather-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-bottom: calc(100vh - 450px);
  margin-left: calc(100% - 200px);
  z-index: 10;
  justify-content: flex-end;
  width: 200px;
}

.weather-content {
  display: flex;
  flex-direction: column;
  background: #dddddd;
  padding: 10px;
  border-radius: 12px;
}

.weather-display-text {
  /* margin: 5px; */
  font-size: medium;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .weather-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    margin-bottom: calc(100vh - 200px);
    margin-left: calc(100% - 200px);
    z-index: 10;
    justify-content: flex-end;
    width: 200px;
  }
}

/* ML MAP */
.slidecontainer {
  width: 100%; /* Width of the outside container */
  margin: 10px 0px 10px 0px;
}

.slide-form-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-left: calc(100% - 220px);
  z-index: 10;
  justify-content: flex-end;
  background: #dddddd;
  padding: 10px;
  border-radius: 12px;
}

@media only screen and (max-width: 600px) {
  .slide-form-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-left: calc(100% - 230px);
    z-index: 10;
    justify-content: flex-end;
    background: #dddddd;
    padding: 10px;
    border-radius: 12px;
  }
}

.slider-settings-text {
  margin-top: 5px;
  font-size: 14px;
  font-weight: bold;
}

.slider-submission {
  margin-top: 5px;
  border-radius: 12px;
  border: solid;
  border-color: #3d3d3d;
  font-size: medium;
}

.slider-submission:active {
  transform: scale(0.95); /* This shrinks the button slightly when it's clicked */
}

/* The slider itself */
.slider {
  margin: 0px 0px 0px 0px;
  appearance: none;
  width: 180px;
  height: 15px;
  border-radius: 5px;  
  background: #ffffff;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%; 
  background: #3d3d3d;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #3d3d3d;
  cursor: pointer;
}

.pop-up-text {
  font-size: smaller;
  font-weight: bold;
}

/* LANDING */
.landing-background-image-container {
  vertical-align: center;
  position: relative;
  text-align: center;
  mask-size: auto auto;
}

.landing-tile-text {
  font-size: calc(20px + 2vmin);
  font-weight: bolder;
  width: 350px;
  color: #ffffff;
}

.landing-tile-text-container {
  position: absolute;
  text-justify: center;
  text-align: center;
  align-items: center;
}

.landing-content-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color:#282c34;
  padding: 25px 50px 25px 50px;
}

.landing-tile {
  width: 30%;
  height: 350px;
  align-items: center;
  display: flex;
}

.landing-top-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.landing-top-content {
  display: flex;
  justify-content: left;
  margin: 50px 30px 15px 30px;
}

.landing-middle-content {
  display: flex;
  justify-content: left;
  margin: 15px 30px 15px 30px;
}

.landing-bottom-content {
  display: flex;
  justify-content: left;
  margin: 15px 30px 50px 30px;
}

.landing-top-title {
  color: #ffffff;
  font-size: calc(18px + 1vmin);
  font-weight: bolder;
  margin-bottom: 30px;
  margin-left: 60px;
  width: 30%;
  min-width: 150px;
}

.landing-top-text {
  color: #ffffff;
  font-size: calc(10px + 1vmin);
  margin-left: 30px;
  margin-right: 30px;
  /* max-width: 550px; */
  width: 60%;
}

.landing-bottom-text {
  color: #ffffff;
  font-size: calc(10px + 1vmin);
  margin-left: 30px;
  margin-right: 30px;
}

.landing-top-image-container {
  width: 100%;
  min-width: 200px;
  border-radius: 20px;
  margin: 30px;
}

.landing-tiles-image-container {
  width: 350px;
  height: 270px;
  border-radius: 20px;
}

.landing-tiles-container {
  background-color: #ffffff;
  min-width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.landing-tile {
  margin: 15px 30px 15px 30px;
  display: flex;
  width: 400px;
  height: auto;
}

.landing-left {
  display: flex;
  width: 58%;
  justify-content: center;}

.landing-left-container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-left: 60px;
}

.landing-right {
  display: flex;
  width: 35%;
  margin-right: 60px;
  min-width: 350px;
  justify-content: center;
  align-items: center;
}

.mapboxgl-map {
  z-index: -1;
}
